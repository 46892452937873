import React, { Component, createRef } from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom'
import {Helmet} from 'react-helmet';
import SectionList from './SectionList';
import { ParallaxProvider } from 'react-scroll-parallax';
import { isMobile  } from 'react-device-detect';
import LOGO_701 from '../../sections/Logo_701/Logo';
import MENU_702 from '../../sections/Menu_702/Menu';
import H_IMAGESLIDER_261 from '../../sections/H_ImageSlider_261/H_ImageSlider';
import H_SECTION1_246 from '../../sections/H_Section1_246/H_Section1';
import H_SECTION2_247 from '../../sections/H_Section2_247/H_Section2';
import H_SECTION3_260 from '../../sections/H_Section3_260/H_Section3';
import H_SECTION4_391 from '../../sections/H_Section4_391/H_Section4';
import H_SECTION5_254 from '../../sections/H_Section5_254/H_Section5';
import FOOTER_253 from '../../sections/Footer_253/Footer';
import SectionShortcut from './SectionShortcut/SectionShortcut';
import HomeSectionShortcut from './SectionShortcut/HomeSectionShortcut';
import styled from 'styled-components';

const MainClass = styled.div`
     min-height: 100vh;
     background: white;
     position: relative;
     z-index: 1;
     box-shadow: 0px 3px 6px #00000029;
`;

class project1 extends Component {
   constructor(props) {
       super(props);
       this.state = {
           currentPage: null,
           currentSection: null,
           override: true,
           darkSection: true,
          section: [
               { id: 1, title: localStorage.getItem('language') === "English"? 'Homepage' : 'Trang Chủ', slug: localStorage.getItem('language') === "English"? 'homepage' : 'trang-chu', has_shortcut: true},
               { id: 2, title: localStorage.getItem('language') === "English"? 'Vision - Mission -  Core Values' : 'Tầm Nhìn - Sứ Mệnh - Giá Trị Cốt Lõi', slug: localStorage.getItem('language') === "English"? 'vision---mission----core-values' : 'tam-nhin---su-menh---gia-trị-cot-loi', has_shortcut: true},
               { id: 3, title: localStorage.getItem('language') === "English"? 'Business Areas' : 'Lĩnh Vực Hoạt Động', slug: localStorage.getItem('language') === "English"? 'business-areas' : 'linh-vuc-hoat-dong', has_shortcut: true},
               { id: 4, title: localStorage.getItem('language') === "English"? 'News And Events' : 'Tin Tức & Sự Kiện', slug: localStorage.getItem('language') === "English"? 'news-and-events' : 'tin-tuc-&-su-kien', has_shortcut: true},
               { id: 5, title: localStorage.getItem('language') === "English"? 'Sustainable Development' : 'Phát Triển Bền Vững', slug: localStorage.getItem('language') === "English"? 'sustainable-development' : 'phat-trien-ben-vung', has_shortcut: true},
               { id: 6, title: localStorage.getItem('language') === "English"? 'Career' : 'Cơ Hội Nghề Nghiệp', slug: localStorage.getItem('language') === "English"? 'career' : 'co-hoi-nghe-nghiep', has_shortcut: true},
          ],
       activeSection: 'Section1',
       };
       this.updateLogo = this.updateLogo.bind(this)
       this.override1 = this.override1.bind(this)
       this.override2 = this.override2.bind(this)
       this.override3 = this.override3.bind(this)
       this.override4 = this.override4.bind(this)
       this.override5 = this.override5.bind(this)
       this.override6 = this.override6.bind(this)
        this.scrollToSection = this.scrollToSection.bind(this)
        this.scrollingHandler = this.scrollingHandler.bind(this)
        this.isStateChanging = false
   }
   componentDidMount() {
       ReactGA.initialize('G-R7TGZTN26B')
       ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener('scroll', this.scrollingHandler, true);
          this.scrollToSection()
   }
   componentWillUnmount(){
       window.removeEventListener('scroll', this.scrollingHandler, true);
   }
     section1 = createRef()
     section2 = createRef()
     section3 = createRef()
     section4 = createRef()
     section5 = createRef()
     section6 = createRef()
   updateLogo(value) {
       this.setState({
           darkSection: value,
       })
   }
   override1(value) {
       this.setState({
       override: value,
       })
   }

   override2(value) {
       this.setState({
       override: value,
       })
   }

   override3(value) {
       this.setState({
       override: value,
       })
   }

   override4(value) {
       this.setState({
       override: value,
       })
   }

   override5(value) {
       this.setState({
       override: value,
       })
   }

   override6(value) {
       this.setState({
       override: value,
       })
   }

     scrolltoSectionHandler = (key) => {
       if (key === 'section1') {
           this.section1.current.scrollIntoView({'behavior': 'smooth', 'block': 'start'});
       } else if (key === 'section2') {
           this.section2.current.scrollIntoView({'behavior': 'smooth', 'block': 'start'});
       } else if (key === 'section3') {
           this.section3.current.scrollIntoView({'behavior': 'smooth', 'block': 'start'});
       } else if (key === 'section4') {
           this.section4.current.scrollIntoView({'behavior': 'smooth', 'block': 'start'});
       } else if (key === 'section5') {
           this.section5.current.scrollIntoView({'behavior': 'smooth', 'block': 'start'});
       } else if (key === 'section6') {
           this.section6.current.scrollIntoView({'behavior': 'smooth', 'block': 'start'});
      }
   };
    scrollingHandler() {
        const sectionsName = ['section1', 'section2', 'section3', 'section4', 'section5', 'section6'];
        const isSectionsDarkLogo = [
       {
           name: 'Section1',
           darkSection: true
       },
       {
           name: 'Section2',
           darkSection: false
       },
       {
           name: 'Section3',
           darkSection: false
       },
       {
           name: 'Section4',
           darkSection: true
       },
       {
           name: 'Section5',
           darkSection: false
       },
       {
           name: 'Section6',
           darkSection: false
       },
        ]
       isSectionsDarkLogo.forEach((sec, i) => {
           let section = this[sectionsName[i]]?.current?.getBoundingClientRect();
           if (section && section.bottom < section.height && section.bottom > -1 && !this.isStateChanging) {
               this.setState({
                   activeSection: sec.name
               })
               if (this.state.darkSection !== sec.darkSection && this.state.currentSection !== sec.name) {
                   this.isStateChanging = true
                   this.setState({
                       currentSection: sec.name,
                       darkSection: sec.darkSection,
                   }, () => {
                       this.isStateChanging = false
                   })
               }
           }
       })
    }
   scrollToSection() {
       let currentLocation = window.location.href
       const hasSectionAnchor = currentLocation.includes('/#')
       const hasTabAnchor = currentLocation.includes('*')
       if (hasSectionAnchor) {
           let anchorSectionId = `${currentLocation.substring(currentLocation.indexOf('#') + 1)}`
           if (hasTabAnchor) {
                const tabSectionId = `${anchorSectionId.substring(anchorSectionId.indexOf('*') + 1)}`
                anchorSectionId = anchorSectionId.substring(0, anchorSectionId.length - 2)
                setTimeout(() => { this.scrollAction(anchorSectionId) }, 1000)
                this.setState({ tabsActive: tabSectionId })
            } else {
                setTimeout(() => { this.scrollAction(anchorSectionId) }, 1000)
            }
        } else {
           setTimeout(() => {window.scrollTo(0, 0)}, 1000)
           this.setState({
               activeSection: 'Section1',
               currentSection: null,
           })
        }
   }
    scrollAction(anchorSectionId) {
       const uri_dec = decodeURIComponent(anchorSectionId)
       const key = this.state.section.filter(x => x.slug == uri_dec).map(obj => `section${obj.id}`)
       if (key) {
                if (key[0] === 'section1') {
                    setTimeout(() => {this.section1.current.scrollIntoView({'behavior': 'smooth', 'block': 'start'})}, 1000)
                } else if (key[0] === 'section2') {
                    setTimeout(() => {this.section2.current.scrollIntoView({'behavior': 'smooth', 'block': 'start'})}, 1000)
                } else if (key[0] === 'section3') {
                    setTimeout(() => {this.section3.current.scrollIntoView({'behavior': 'smooth', 'block': 'start'})}, 1000)
                } else if (key[0] === 'section4') {
                    setTimeout(() => {this.section4.current.scrollIntoView({'behavior': 'smooth', 'block': 'start'})}, 1000)
                } else if (key[0] === 'section5') {
                    setTimeout(() => {this.section5.current.scrollIntoView({'behavior': 'smooth', 'block': 'start'})}, 1000)
                } else if (key[0] === 'section6') {
                    setTimeout(() => {this.section6.current.scrollIntoView({'behavior': 'smooth', 'block': 'start'})}, 1000)
                }
       }
   }
   render() {
       let AllSections = [];
       if (this.state.section) {
           this.state.section.map((s, i) => {
               let section;
               switch (i) {
                   case 0:
                       section = (
                           <div ref={this.section1}>
                               <H_IMAGESLIDER_261
                                   updateLogo={this.updateLogo}
                                   override={this.override1}
                                   darkSection={this.state.darkSection}
                               />
                           </div>
                       );
                       break;
                   case 1:
                       section = (
                           <div ref={this.section2}>
                               <H_SECTION1_246
                                   updateLogo={this.updateLogo}
                                   override={this.override2}
                                   darkSection={this.state.darkSection}
                               />
                           </div>
                       );
                       break;
                   case 2:
                       section = (
                           <div ref={this.section3}>
                               <H_SECTION2_247
                                   updateLogo={this.updateLogo}
                                   override={this.override3}
                                   darkSection={this.state.darkSection}
                               />
                           </div>
                       );
                       break;
                   case 3:
                       section = (
                           <div ref={this.section4}>
                               <H_SECTION3_260
                                   updateLogo={this.updateLogo}
                                   override={this.override4}
                                   darkSection={this.state.darkSection}
                               />
                           </div>
                       );
                       break;
                   case 4:
                       section = (
                           <div ref={this.section5}>
                               <H_SECTION4_391
                                   updateLogo={this.updateLogo}
                                   override={this.override5}
                                   darkSection={this.state.darkSection}
                               />
                           </div>
                       );
                       break;
                   case 5:
                       section = (
                           <div ref={this.section6}>
                               <H_SECTION5_254
                                   updateLogo={this.updateLogo}
                                   override={this.override6}
                                   darkSection={this.state.darkSection}
                               />
                           </div>
                       );
                       break;
                       default:
                           break;
                   }
           AllSections.push(section);
           });
       }
       let sec = this.state.section.filter(sec => sec.has_shortcut === true).map((s, i) => {
           let activeDot = this.state.activeSection === 'Section' + (i) ? true : false
           let ref = 'section' + s.id
           return (
               <SectionShortcut
                   key={s.id}
                   title={s.title}
                   activeDot={activeDot}
                   onClick={() => this.scrolltoSectionHandler(ref)}
               />
           );
       });
       let homeSectionShortcut = !isMobile? <HomeSectionShortcut section={sec}  /> : null 
         let helmet = localStorage.getItem('language') === "English" ? <title> Home | Phenikaa</title> : <title> Trang Chủ | Phenikaa</title>
         let description = localStorage.getItem('language') === "English" ? <meta name="description" content="Phenikaa Group focuses on the core sectors: Technology; Industrial Manufacturing; Scientific Research; and Education and Training; Medical health" /> : <meta name="description" content="Phenikaa là tập đoàn kinh tế đa ngành hoạt động trên các lĩnh vực cốt lõi: Công nghệ; Công nghiệp; Nghiên cứu khoa học; Giáo dục đào tạo; Chăm sóc sức khỏe;" />
         let keywords = localStorage.getItem('language') === "English" ? <meta name="keywords" content="Phenikaa, phenikaa group, technology, vicostone, Science Research, Phenikaa recuiment, sustainable development" /> : <meta name="keywords" content="test vn, Phenikaa, tập đoàn phenikaa, công nghệ, vicostone, Nghiên cứu khoa học, Tuyển dụng Phenikaa, phát triển bền vững" />
       return (
           <div style={{}}>
               <Helmet>
                   {helmet}
                   {description}
                   {keywords}
               </Helmet>
               <LOGO_701 logoColor={this.state.darkSection}/>
               <MENU_702 logoColor={this.state.darkSection}/>
               <MainClass>
                   <ParallaxProvider >
                       <React.Fragment>
                               {AllSections.length > 0 ? (
                                   <SectionList sections={AllSections} />
                               ) : (
                                   <></>
                               )}
                       </React.Fragment>
                   </ParallaxProvider>
               </MainClass>
               <FOOTER_253/>
               {homeSectionShortcut}
           </div>
       );
   }
}
export default withRouter(project1);