import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import HorizontalScroll from '../components/HorizontalScroll/HorizontalScroll';
import defaultImage from '../assets/default-image.svg';
import classes from './TabContent.module.css';

export default class TabContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			props: false,
			isDetail: false,

			defaultSlider: 4,
		};
	}

	componentDidMount() {
		this.setState({
			props: this.props,
		});

		if (window.screen.width <= 1400 && window.screen.width > 1024) {
			this.setState({
				defaultSlider: 3,
			});
		} else if (window.screen.width <= 1024 && window.screen.width > 480) {
			this.setState({
				defaultSlider: 2,
			});
		} else {
			this.setState({
				defaultSlider: 4,
			});
		}
	}

	componentDidUpdate() {
		if (this.props) {
			if (this.state.props !== this.props) {
				this.setState({
					props: this.props,
				});
			}
		}
	}

	getHtmlParagraph(str) {
		if (str && str !== '') {
			return str
				.replace(/<\/?p>/gi, ' ')
				.replace(/<p>/gi, ' ')
				.replace(/&nbsp;/gi, ' ');
		}
	}

	toggleDetail = () => {
		this.setState((prevState) => {
			return { isDetail: !prevState.isDetail };
		});
	};

	getUrlParser(url) {
		if (url !== '/') {
			return url.includes('http') ? (
				<a className={classes.customLink} href={url} target='_blank' rel='noopener noreferrer'>
					<div className={classes.outerCircle}>
						<div className={classes.innerCircle}></div>
					</div>
					<p className={classes.viewMore}>{this.props.viewDetails}</p>
				</a>
			) : (
				<Link className={classes.customLink} to={url}>
					<div className={classes.outerCircle}>
						<div className={classes.innerCircle}></div>
					</div>
					<p className={classes.viewMore}>{this.props.viewDetails}</p>
				</Link>
			);
		} else {
			let isNull = classes.isNull;
			return (
				<Link className={[classes.customLink, isNull].join(' ')} to={url}>
					<div className={classes.outerCircle}>
						<div className={classes.innerCircle}></div>
					</div>
					<p className={classes.viewMore}>{this.props.viewDetails}</p>
				</Link>
			);
		}
	}

	checkingFileExtension(file, alts, isOdd) {
		let fileType;
		let typeVid = ['webm', 'mkv', 'flv', 'gif', 'avi', 'mov', 'wmv', 'mp4', '3gp'];
		let typeImg = ['jpg', 'jpeg', 'png', 'svg', 'webp', 'bmp', 'tiff', 'exif'];
		let typeDoc = ['doc', 'docx', 'odt', 'pdf', 'xls', 'xlsx', 'ods', 'ppt', 'pptx', 'txt'];
		let extension = file && file !== 'null' ? file.substring(file.lastIndexOf('.') + 1) : 'default';
		if (typeVid.includes(extension)) {
			fileType = (
				<video className={classes.isvideo} controls loop={true} autoPlay='autoplay' playsInline>
					<source src={file} type='video/mp4' />
				</video>
			);
		} else if (typeImg.includes(extension)) {
			fileType = <img className={isOdd ? classes.imgLogo : classes.imgLogo2} src={file} alt={alts} />;
		} else if (typeDoc.includes(extension)) {
			fileType = 'doc';
		} else {
			fileType = <img className={isOdd ? classes.imgLogo : classes.imgLogo2} src={file} alt={alts} />;
		}
		return fileType;
	}

	render() {
		let title,
			desc,
			img,
			content,
			link,
			detailContent,
			contentBack,
			contentBackTab,
			subsText,
			subsidaries,
			contentSlider,
			contentSliderTab = null;
		let customMargin = !this.state.isDetail ? 'mb-5' : '';

		const { props } = this.state;

		if (props) {
			if (props.tabs[0]) {
				// handling only Highlight
				props.tabs[0].forEach((t, i) => {
					if (props.i === i) {
						let contentImg = t?.assets[1]?.file_big ? t?.assets[1]?.file_big || t?.assets[1]?.file : defaultImage;
						let imgDescription = t?.assets[1]?.description;
						img = <img className={[classes.imgContent].join(' ')} src={contentImg} alt={imgDescription} />;

						desc = (
							<span className={classes.content}>
								<p>{ReactHtmlParser(t.article_language ? t.article_language?.summary : '')}</p>
							</span>
						);

						content = <div className={classes.contentDesc}>{ReactHtmlParser(t.article_language ? t.article_language.content : '')}</div>;
					}
				});
			}

			if (props.data) {
				props.data.forEach((d, i) => {
					// handling only detail content
					if (props.i === i) {
						detailContent = d?.data.map((dt, idx) => {
							let contents;
							let alts = dt.assets ? dt.assets[0]?.description : '';
							if ((idx + 1) % 2 === 0) {
								contents = (
									<>
										{dt.article_language?.title ? (
											<h2 className={classes.titleContent}>{ReactHtmlParser(dt.article_language?.title)}</h2>
										) : (
											<></>
										)}
										<hr />
										<div className={classes.allContentC}>
											<div className={classes.boxPar}>
												<p className={classes.txtParC}>{ReactHtmlParser(dt.article_language?.content)}</p>
											</div>
											<div className={classes.absImg}>
												{this.checkingFileExtension(
													dt.assets[0]
														? dt.assets[0]?.asset_type !== 'Image'
															? dt.assets[0]?.file
															: dt.assets[0]?.file_small
														: defaultImage,
													alts,
													false
												)}
												{/* <img className={classes.imgLogo2} src={dt.assets ? dt.assets[0]?.file_small : require("../assets/default-image.svg")} alt={alts} /> */}
											</div>
										</div>
									</>
								);
							} else {
								contents = (
									<>
										{dt.article_language?.title ? (
											<h2 className={classes.titleContent}>{ReactHtmlParser(dt.article_language?.title)}</h2>
										) : (
											<></>
										)}
										{idx > 0 ? <hr /> : dt.article_language?.title ? <hr /> : <></>}
										<div className={classes.allContentB}>
											<div className={classes.txtParB2}>
												{this.checkingFileExtension(
													dt.assets[0]
														? dt.assets[0]?.asset_type !== 'Image'
															? dt.assets[0]?.file
															: dt.assets[0]?.file_small
														: defaultImage,
													alts,
													true
												)}
											</div>
											<div className={classes.txtParB}>
												<div className={classes.intxtParb}>
													<p className={classes.txtReal}>{ReactHtmlParser(dt.article_language?.content)}</p>
												</div>
											</div>
										</div>
									</>
								);
							}
							return contents;
						});
					}
				});
			}

			if (props.dataExt && props.dataExt.length > 0) {
				// handling only subsidiaries list
				let cLink = props.property[1]?.subsidiaries ? props.property[1]?.subsidiaries?.sections?.url : '';
				subsText = (
					<Link to={cLink} style={{ color: 'black' }} className='text-decoration-none'>
						<p>
							<strong>
								{props.property[1]?.subsidiaries ? props.property[1]?.subsidiaries?.sections?.widget_contents[0]?.content : 'Subsidiaries'}
							</strong>
						</p>
					</Link>
				);

				subsidaries = props.dataExt.map((s, i) => {
					let url = s.assets[0] ? s.assets[0].file_small || s.assets[0].file : defaultImage;
					let description = s.assets[0]?.description;

					let cLinkTo = props.property[1]?.subsidiaries
						? `${props.property[1]?.subsidiaries?.sections?.url}/?tab=${props.i + 1}&index=${i + 1}`
						: '';
					return (
						<Link to={cLinkTo}>
							<div className={classes.box}>
								<img src={url} alt={description}></img>
								<div className={classes.boxMask}></div>
								<div className={classes.contentBack}>
									<div className={classes.boxContentIn}>
										<div className='text-right mt-auto mb-auto'>
											<p>{this.getHtmlParagraph(s.article_language.content)}</p>
										</div>
									</div>
								</div>
							</div>
						</Link>
					);
				});
			}

			if (!this.state.isDetail) {
				// handling only link read more/less with content subsidiaries
				link = (
					<div className={[classes.contentLink].join(' ')} onClick={this.toggleDetail}>
						<div className='d-flex'>
							<div className={classes.outerCircle}>
								<div className={classes.innerCircle}></div>
							</div>
							<p className={classes.contentLink}>{props.property ? props.property[0]?.readMore : 'Read more'}</p>
						</div>
					</div>
				);
			} else if (this.state.isDetail) {
				let isNull = null;
				let customLink = !this.state.isDetail ? '' : classes.contentLinkDetail;
				let contentSliderD = <HorizontalScroll>{subsidaries}</HorizontalScroll>;
				let contentBackD = (
					<div className={[classes.contentLink, customLink, isNull].join(' ')} onClick={this.toggleDetail}>
						<div className='d-flex'>
							<div className={classes.outerCircle}>
								<div className={classes.innerCircle}></div>
							</div>
							<p className={classes.contentLink}>{props.property ? props.property[0]?.readLess : 'Read less'}</p>
						</div>
					</div>
				);

				if (this.state.defaultSlider === 2) {
					contentSliderTab = contentSliderD;
					contentBackTab = contentBackD;
				} else {
					contentSlider = contentSliderD;
					contentBack = contentBackD;
				}
			}
		}

		let animate = this.state.isDetail ? classes.fadeInBottom : classes.fadeOutTop;

		return (
			<div className={classes.sectionIndustry}>
				<div className={['container d-flex flex-column', classes.customContainer].join(' ')}>
					<div className={[classes.boxImage].join(' ')}>
						<div className={classes.animate}>{img}</div>
					</div>
					<div className={['d-flex', customMargin].join(' ')}>
						<div className={classes.boxContent}>
							{/* {title} */}
							{desc}
							{link}
						</div>
						<div className={classes.boxOverflow}>
							<div className={classes.boxContentOverflow}>
								<div className={[classes.contentOverflow].join(' ')}>{content}</div>
							</div>
						</div>
					</div>

					{this.state.isDetail ? (
						<div div className={[classes.detailContent, animate].join(' ')}>
							{detailContent}
							{subsText}
							{contentSlider}
						</div>
					) : (
						<></>
					)}
					{contentBack}
				</div>

				{contentSliderTab}
				{contentBackTab}
			</div>
		);
	}
}
